$(document).ready(function() {
	$('#prod-gallery').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: false,
		fade: true,
		rtl: ($("html").attr('dir') == 'rtl' ? true : false),
		autoplay: false,
		infinite: true,
		asNavFor: '#prod-gallery-navi',
	});
	$('#prod-gallery-navi').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		asNavFor: '#prod-gallery',
		dots: false,
		arrows: false,
		focusOnSelect: true,
		infinite: true,
		vertical: false,
		verticalSwiping: false,
		rtl: ($("html").attr('dir') == 'rtl' ? true : false),
		responsive: [{
				breakpoint: 550,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 350,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				}
			}
		]
	});

	$('.prod-gallery-lightbox').lightGallery({
		selector: '.prod-gallery__img-big',
		useLeft: true,
		thumbnail: true,
		animateThumb: false,
		showThumbByDefault: false,
		zoom: false,
	});

});