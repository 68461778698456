$(document).ready(function() {

	$('.cat-nav__slider').slick({
		slidesToShow: 9,
		slidesToScroll: 1,
		dots: false,
		arrows: true,
		infinite: true,
		rtl: ($("html").attr('dir') == 'rtl' ? true : false),
		responsive: [{
				breakpoint: 1200,
				settings: {
					slidesToShow: 8,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 7,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 993,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 766,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 650,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 520,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 415,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				}
			}
		]
	});

});