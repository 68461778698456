$(document).ready(function() {

	function add(type) {
		if (type == 'hight') {
			$(items[i]).addClass('hp-cat-boxes__item_hight');
		} else {
			$(items[i]).addClass('hp-cat-boxes__item_long');
		}
	}

	var items = $('.hp-cat-boxes').children();
	var long1 = 0;
	var long2 = 3;
	var step = 4;

	for (var i = 0; i < 4; i++) {
		switch (i) {
			case long1:
				add('hight');
				long1 += step;
				break;
			case long2:
				add('long');
				long2 += step;
				break;
		}
	}

	// slider with central big slider

	var dirRtlFlag = false;
	if ($("html").attr('dir') == 'rtl') {
		dirRtlFlag = true;
	}


	$('.prod-sec-slider').slick({
		centerMode: true,
		centerPadding: '0px',
		slidesToShow: 3,
		focusOnSelect: true,
		rtl: dirRtlFlag,
		arrows: false,
		dots: true,
		responsive: [{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: false,
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: false,
					slidesToShow: 1,
					// centerPadding: '60px',
				}
			}
		]
	});

})