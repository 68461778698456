$(document).ready(function() {

	function add(type) {
		if (type == 'hight') {
			$(items[i]).addClass('cat-boxes__item_hight');
		} else {
			$(items[i]).addClass('cat-boxes__item_long');
		}
	}

	var items = $('.cat-boxes').children('.cat-boxes__item');
	var long1 = 0;
	var long2 = 3;
	var step = 4;

	for (var i = 0; i < items.length; i++) {
		switch (i) {
			case long1:
				add('hight');
				long1 += step;
				break;
			case long2:
				add('long');
				long2 += step;
				break;
		}
	}

});